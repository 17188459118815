<template>
	<div class="pump-house">
		<sidebar />
		<div class="right">
			<header-top />
			<div class="page-title">
				<span>二次供水泵房管理 泵房资产</span>
				<div class="title-right">
					<ul>
						<li>首页</li>
						<li>/</li>
						<li>资产管理</li>
						<li>/</li>
						<li>泵房资产</li>
					</ul>
				</div>
			</div>
			<div class="box">
				<div class="community-select">
					<div>
						<!-- <el-select v-model="value" placeholder="所在片区" size="medium">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select> -->

						<el-input
							size="medium"
							style="width: 220px"
							placeholder="小区名称"
						></el-input>
						<el-button
							icon="el-icon-search"
							type="primary"
							class="ml-2"
							size="medium"
							>查询</el-button
						>
					</div>
					<div class="mt-2">
						<el-tree
							class="filter-tree"
							:data="treeData"
							:props="treeProps"
							default-expand-all
							ref="tree"
						>
						</el-tree>
					</div>
				</div>

				<div class="mb-2 ml-4 pump-list">
					<el-tabs type="border-card" v-model="activeTable">
						<el-tab-pane label="水泵" name="pumpList">
							<vxe-table
								class="vxe-table-wrap"
								cell-class-name="vxe-table-cell "
								size="mini"
								height="700px"
								stripe
								highlight-hover-row
								border
								show-overflow
								keep-source
								auto-resize
								:data="communityList"
								show-footer
							>
								<vxe-table-column
									field="name"
									title="小区名称"
									header-align="center"
									align="left"
									cell-type="string"
								>
								</vxe-table-column>

								<vxe-table-column
									field="province"
									title="省"
									header-align="center"
									align="left"
									width="90"
									cell-type="string"
								>
								</vxe-table-column>
								<vxe-table-column
									field="city"
									title="市"
									header-align="left"
									align="left"
									cell-type="string"
								>
								</vxe-table-column>

								<vxe-table-column
									field="district"
									title="区"
									header-align="left"
									align="left"
									cell-type="string"
								>
								</vxe-table-column>

								<vxe-table-column
									field="area"
									title="片区"
									header-align="left"
									align="left"
									cell-type="string"
								>
								</vxe-table-column>

								<vxe-table-column
									field="addr"
									title="地址"
									header-align="left"
									align="left"
									cell-type="string"
								>
								</vxe-table-column>
							</vxe-table>
							<div>
								<el-pagination
									background
									layout="prev, pager, next, sizes,jumper,total"
									:total="1000"
								>
								</el-pagination>
							</div>
						</el-tab-pane>
						<el-tab-pane label="蓄水池" name="pool">配置管理</el-tab-pane>
						<el-tab-pane label="环境采集设备" name="environment"
							>环境采集设备</el-tab-pane
						>
					</el-tabs>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import sidebar from '../components/sidebar.vue'
import headerTop from '../components/header.vue'
export default {
	name: 'pump-house',
	components: {
		// Com,
		sidebar,
		headerTop,
	},
	data() {
		return {
			activeTable: 'pumpList',

			page5: {
				currentPage: 1,
				pageSize: 10,
				totalResult: 300,
			},
			communityList: [
				{
					area: 'G24',
					ext:
						'小区编号：GL0264\n建设类型：商品房\n管理情况：自管\n归属社区：西湖社区\n一级监控表：1001100727（DN40）\n楼栋总数：1\n总户数：73\n居民户数：73\n非居民户数：1\n户改情况：未户改\n接收情况：完全接收\n直供总户数：0\n多层直供户数：0\n高层直供户数：0\n超高层直供户数：0\n增压总户数：0\n多层增压户数：0\n高层增压户数：0\n超高层增压户数：0\n混合增压户数：0\n小区干管材质：球墨铸铁\n小区支管材质：钢塑\n监控表是否在小区内：是\n计量消防栓数量：0\nDN15公建表数量：0\nDN20公建表数量：0\nDN25公建表数量：0\nDN40公建表数量：0\n填表人：林莉\n填表时间：2016-7-11\n表位情况：户内\n远传情况：无线\n监控表：一级：1001100727\n商业表：1001100726\n总阀是否在小区内：是\n',
					province: '福建省',
					city: '福州市',
					createTime: 1574305975274,
					district: '鼓楼区',
					name: '东胜大厦',
					company: 'e1',
					id: 514,
					addr: '鼓西路380号',
				},
				{
					area: 'G36',
					ext:
						'小区编号：GL1001\n建设类型：商品房\n管理情况：物业\n楼栋总数：2\n总户数：447\n居民户数：444\n非居民户数：3\n户改情况：部分户改\n接收情况：部分接收\n直供总户数：0\n多层直供户数：0\n高层直供户数：0\n超高层直供户数：0\n增压总户数：0\n多层增压户数：0\n高层增压户数：0\n超高层增压户数：0\n混合增压户数：0\n小区干管材质：球墨铸铁\n小区支管材质：钢塑\n监控表是否在小区内：是\n计量消防栓数量：0\nDN15公建表数量：0\nDN20公建表数量：0\nDN25公建表数量：0\nDN40公建表数量：0\n填表人：黄京津\n填表时间：2016-7-7\n表位情况：混合\n远传情况：无\n监控表：1013008678      \r\n1013008677\n消防表：1013008721\n总阀是否在小区内：是\n',
					province: '福建省',
					city: '福州市',
					createTime: 1574305976818,
					district: '鼓楼区',
					name: '国发广场',
					company: 'e1',
					id: 770,
					addr: '华林路138号',
				},
				{
					area: 'G44',
					ext:
						'小区编号：GL1471\n建设类型：单位房\n管理情况：物业\n楼栋总数：1\n总户数：168\n居民户数：168\n非居民户数：0\n户改情况：未户改\n接收情况：未接收\n直供总户数：0\n多层直供户数：0\n高层直供户数：0\n超高层直供户数：0\n增压总户数：0\n多层增压户数：0\n高层增压户数：0\n超高层增压户数：0\n混合增压户数：0\n小区干管材质：球墨铸铁\n小区支管材质：钢塑\n监控表是否在小区内：是\n计量消防栓数量：0\nDN15公建表数量：0\nDN20公建表数量：0\nDN25公建表数量：0\nDN40公建表数量：0\n填表人：潘玲珊\n填表时间：2016-7-12\n表位情况：户内\n远传情况：无\n总阀是否在小区内：是\n',
					province: '福建省',
					city: '福州市',
					createTime: 1574305978455,
					district: '鼓楼区',
					name: '瑞景大厦',
					company: 'e1',
					id: 1026,
					addr: '北环西路66号',
				},
				{
					area: '台江区',
					ext: '',
					zipCode: '350004',
					note: '',
					province: '福建省',
					city: '福州市',
					createTime: 1566972515746,
					district: '台江区',
					name: '香开长龙',
					company: 'e1',
					id: 3,
					addr: '福建福州市台江区',
				},
				{
					area: 'G19',
					ext:
						'小区编号：GL2303\n建设类型：单位房\n管理情况：自管\n楼栋总数：2\n总户数：64\n居民户数：64\n非居民户数：3\n户改情况：完全户改\n接收情况：未接收\n直供总户数：0\n多层直供户数：0\n高层直供户数：0\n超高层直供户数：0\n增压总户数：0\n多层增压户数：0\n高层增压户数：0\n超高层增压户数：0\n混合增压户数：0\n小区干管材质：球墨铸铁\n小区支管材质：钢塑\n监控表是否在小区内：是\n计量消防栓数量：0\nDN15公建表数量：0\nDN20公建表数量：0\nDN25公建表数量：0\nDN40公建表数量：0\n填表人：林长青\n填表时间：2016-8-2\n表位情况：梯位\n远传情况：无\n物业绿化表：2030446966\n总阀是否在小区内：是\n',
					province: '福建省',
					city: '福州市',
					createTime: 1574305973787,
					district: '鼓楼区',
					name: '消防支队宿舍/财政局宿舍',
					company: 'e1',
					id: 259,
					addr: '古田路129号、古田路129号',
				},
				{
					area: 'G24',
					ext:
						'小区编号：GL0265\n建设类型：商品房\n管理情况：无人管\n归属社区：达明社区\n一级监控表：1001120448（DN40）\n楼栋总数：4\n总户数：170\n居民户数：170\n非居民户数：21\n户改情况：未户改\n接收情况：完全接收\n直供总户数：0\n多层直供户数：0\n高层直供户数：0\n超高层直供户数：0\n增压总户数：0\n多层增压户数：0\n高层增压户数：0\n超高层增压户数：0\n混合增压户数：0\n小区干管材质：球墨铸铁\n小区支管材质：钢塑\n监控表是否在小区内：是\n计量消防栓数量：0\nDN15公建表数量：0\nDN20公建表数量：0\nDN25公建表数量：0\nDN40公建表数量：0\n填表人：林莉\n填表时间：2016-7-3\n表位情况：户内\n远传情况：无\n监控表：一级：1001120448\n商业表：2030349649\r\n2030349713\r\n2030349721\r\n2030349729\r\n2030349815\r\n2030349816\r\n2030349817\r\n2030349818\r\n2030349819\r\n2030349820\r\n2030349821\r\n2030349822\r\n2030349823',
					province: '福建省',
					city: '福州市',
					createTime: 1574305975280,
					district: '鼓楼区',
					name: '元帅庙新村',
					company: 'e1',
					id: 515,
					addr: '元帅庙新村',
				},
				{
					area: 'G36',
					ext:
						'小区编号：GL1004\n建设类型：单位房\n管理情况：自管\n楼栋总数：3\n总户数：101\n居民户数：97\n非居民户数：4\n户改情况：完全户改\n接收情况：未接收\n直供总户数：0\n多层直供户数：0\n高层直供户数：0\n超高层直供户数：0\n增压总户数：0\n多层增压户数：0\n高层增压户数：0\n超高层增压户数：0\n混合增压户数：0\n小区干管材质：球墨铸铁\n小区支管材质：钢塑\n监控表是否在小区内：是\n计量消防栓数量：0\nDN15公建表数量：0\nDN20公建表数量：0\nDN25公建表数量：0\nDN40公建表数量：0\n填表人：黄京津\n填表时间：2016-7-13\n表位情况：落地\n远传情况：无\n消防表：2030018877\n物业绿化表：2030022593      \r\n2030022594\n总阀是否在小区内：是\n',
					province: '福建省',
					city: '福州市',
					createTime: 1574305976824,
					district: '鼓楼区',
					name: '市五交化站宿舍',
					company: 'e1',
					id: 771,
					addr: '华林路河边路3号',
				},
				{
					area: 'G45',
					ext:
						'小区编号：GL1014\n建设类型：安置房\n管理情况：自管\n一级监控表：2030043469 40\n楼栋总数：1\n总户数：33\n居民户数：30\n非居民户数：3\n户改情况：完全户改\n接收情况：未接收\n直供总户数：0\n多层直供户数：0\n高层直供户数：0\n超高层直供户数：0\n增压总户数：0\n多层增压户数：0\n高层增压户数：0\n超高层增压户数：0\n混合增压户数：0\n小区干管材质：球墨铸铁\n小区支管材质：钢塑\n监控表是否在小区内：是\n计量消防栓数量：0\nDN15公建表数量：0\nDN20公建表数量：0\nDN25公建表数量：0\nDN40公建表数量：0\n填表人：翁姗姗\n填表时间：2016-7-15\n表位情况：梯位\n远传情况：无\n监控表：2030043469\n物业绿化表：2030043564\n总阀是否在小区内：是\n',
					province: '福建省',
					city: '福州市',
					createTime: 1574305978460,
					district: '鼓楼区',
					name: '福建省水土保持试验站宿舍',
					company: 'e1',
					id: 1027,
					addr: '台后路9号新1座',
				},
				{
					area: 'G10',
					ext:
						'小区编号：GL2007\n建设类型：单位房\n管理情况：自管\n楼栋总数：4\n总户数：134\n居民户数：0\n非居民户数：0\n户改情况：未户改\n接收情况：完全接收\n直供总户数：0\n多层直供户数：0\n高层直供户数：0\n超高层直供户数：0\n增压总户数：0\n多层增压户数：0\n高层增压户数：0\n超高层增压户数：0\n混合增压户数：0\n小区干管材质：球墨铸铁\n小区支管材质：钢塑\n监控表是否在小区内：是\n计量消防栓数量：0\nDN15公建表数量：0\nDN20公建表数量：0\nDN25公建表数量：0\nDN40公建表数量：0\n填表人：刘韵\n填表时间：2016-7-8\n表位情况：户内\n远传情况：无线\n远传厂家：智恒\n监控表：1001140577   1001140005\n商业表：1001180711\n物业绿化表：2030217784  2030217785  2030217786\n总阀是否在小区内：是\n',
					province: '福建省',
					city: '福州市',
					createTime: 1574305971999,
					district: '鼓楼区',
					name: '二中宿舍',
					company: 'e1',
					id: 4,
					addr: '光禄坊101号',
				},
				{
					area: 'G19',
					ext:
						'小区编号：GL2308\n建设类型：单位房\n管理情况：自管\n楼栋总数：1\n总户数：14\n居民户数：14\n非居民户数：3\n户改情况：完全户改\n接收情况：未接收\n直供总户数：0\n多层直供户数：0\n高层直供户数：0\n超高层直供户数：0\n增压总户数：0\n多层增压户数：0\n高层增压户数：0\n超高层增压户数：0\n混合增压户数：0\n小区干管材质：球墨铸铁\n小区支管材质：钢塑\n监控表是否在小区内：是\n计量消防栓数量：0\nDN15公建表数量：0\nDN20公建表数量：0\nDN25公建表数量：0\nDN40公建表数量：0\n填表人：林长青\n填表时间：2016-8-2\n表位情况：落地\n远传情况：无\n监控表：2030129886\n消防表：2030129901\n总阀是否在小区内：是\n',
					province: '福建省',
					city: '福州市',
					createTime: 1574305973792,
					district: '鼓楼区',
					name: '劳动大厦宿舍',
					company: 'e1',
					id: 260,
					addr: '古乐路128号',
				},
			],

			treeData: [
				{
					id: 1,
					label: '一级 1',
					children: [
						{
							id: 4,
							label: '二级 1-1',
							children: [
								{
									id: 9,
									label: '三级 1-1-1',
								},
								{
									id: 10,
									label: '三级 1-1-2',
								},
							],
						},
					],
				},
				{
					id: 2,
					label: '一级 2',
					children: [
						{
							id: 5,
							label: '二级 2-1',
						},
						{
							id: 6,
							label: '二级 2-2',
						},
					],
				},
				{
					id: 3,
					label: '一级 3',
					children: [
						{
							id: 7,
							label: '二级 3-1',
						},
						{
							id: 8,
							label: '二级 3-2',
						},
					],
				},
			],

			treeProps: {
				children: 'children',
				label: 'label',
			},
		}
	},
}
</script>

<style lang="scss">
.pump-house {
	.el-tabs--border-card {
		box-shadow: none;
	}
}
</style>

<style lang="scss" scoped>
.pump-house {
	*:focus,
	*:active {
		outline: none !important;
	}
	li {
		list-style: none;
	}
	background: url(~@/assets/images/energyManage/background.jpg) 700px 700px;
	display: flex;
	.right {
		flex: 8;
		padding: 0 20px;
		.page-title {
			font-size: 15px;
			border-bottom: 1px solid rgba(255, 255, 255, 0.15);
			margin: 0;
			display: flex;
			color: #fff;
			justify-content: space-between;
			align-items: center;
			padding: 10px 0;
			.title-right {
				ul {
					display: flex;
					margin: 0;
					li {
						font-size: 13px;
						margin: 0 5px;
						cursor: pointer;
					}
					li:last-child {
						color: #ffd600;
					}
					li:nth-child(1):hover,
					li:nth-child(3):hover,
					li:nth-child(5):hover {
						color: #ffd600;
					}
				}
			}
		}
		.box {
			display: flex;
			.community-select {
				flex: 0 0 auto;
			}
			.pump-list {
				flex: 1 1 auto;
			}
		}
		::v-deep {
			.vxe-table--body-wrapper {
				color: #fff;
				border: 1px solid rgba(255, 255, 255, 0.55);
				.vxe-cell {
					border-bottom: 1px solid rgba(255, 255, 255, 0.55);
					padding: 2px 5px;
					background: rgba(0, 0, 0, 0.35);
				}
			}
			.vxe-table--header-wrapper {
				border: 1px solid rgba(255, 255, 255, 0.55);
				background: rgba(0, 0, 0, 0.75);
        border-bottom: none;
				.vxe-cell {
					overflow: hidden;
					color: #fff;
					padding: 5px;
					text-align: center;
				}
			}
			.vxe-table--render-default .vxe-table--empty-block {
				display: none;
				visibility: hidden;
			}
			.vxe-table--empty-content {
				display: none;
			}
			.vxe-table--empty-placeholder {
				border: 1px solid rgba(255, 255, 255, 0.55);
				border-top: none;
				display: none;
			}
			.el-pagination__jump,
			.el-pagination__total {
				color: #fff;
				margin: 0 10px;
			}
			.el-pagination {
				text-align: right;
				margin-top: 20px;
			}
			.el-tabs--border-card,
			.el-tabs--border-card > .el-tabs__header {
				background: transparent;
				color: #fff;
			}
			.el-tabs--border-card > .el-tabs__header .el-tabs__item {
				color: #fff;
			}
			.el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
				color: #d12;
			}
			.el-tree {
				color: #fff;
				background: rgba(0, 0, 0, 0.35);
			}
			.el-tree-node__content:hover {
				background-color: rgba(0, 0, 0, 0.75);
			}
      .el-tree-node:focus > .el-tree-node__content{
        background-color: rgba(0, 0, 0, 0.75);
      }
		}
	}
}
</style>
